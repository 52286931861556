import React, { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';

interface OrderItem {
  id: string;
  title: string;
  quantity: number;
  price: number;
}

interface CurrentOrderPanelProps {
  currentOrder: OrderItem[];
  updateOrderQuantity: (itemId: string, newQuantity: number) => void;
  clearOrder: () => void;
  calculateTotal: {
    subtotal: number;
    discount: number;
    tax: number;
    total: number;
  };
  stock: Record<string, number>; // Change to Record<string, number>
}

const CURRENCY_APP = process.env.REACT_APP_CURRENCY;
const truncate = require('truncate');

const CurrentOrderPanel: React.FC<CurrentOrderPanelProps> = ({
  currentOrder,
  updateOrderQuantity,
  clearOrder,
  calculateTotal,
  stock, // Change to Record<string, number>
}) => {
  const handleQuantityChange = (itemId: string, newQuantity: number) => {
    const stockItem = stock[itemId];
    if (newQuantity >= 0 && (stockItem === undefined || newQuantity <= stockItem)) {
      updateOrderQuantity(itemId, newQuantity);
    }
  };
  const [selectedMethod, setSelectedMethod] = useState('0'); // Default to 'Cash'

  const paymentMethods = [
    { value: '0', label: 'Cash', icon: '/media/icons/duotune/finance/fin003.svg', size: 'svg-icon-2hx mb-2' },
    { value: '1', label: 'Card', icon: '/media/icons/duotune/finance/fin002.svg', size: 'svg-icon-2hx mb-2' },
    { value: '2', label: 'M-Pesa', icon: '/media/svg/mpesa.svg', size: 'svg-icon-2hx mb-2' },
    { value: '3', label: 'Bank', icon: '/media/icons/duotune/finance/fin001.svg', size: 'svg-icon-2hx mb-2' },
    { value: '4', label: 'Other Bank', icon: '/media/icons/duotune/finance/fin002.svg', size: 'svg-icon-2hx mb-2' },
  ];

  const handleMethodChange = (value: any) => {
    setSelectedMethod(value);
  };

  return (
    <div className="card card-flush h-lg-100" id="kt_pos_form">
      


      <div className="card-header rounded-0 rounded-top border-0 py-3 px-6">
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
            <div className='mb-2'>
              <h3 className='fw-bolder my-2'>Current Order</h3>
            </div>
          </div>
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <div className='mb-2'>
            <button onClick={clearOrder} className="btn btn-light-primary fs-4 fw-bold py-4">
            Clear All
          </button>
            </div>
          </div>
        </div>
      <div className="card-body py-0 px-6">
        <div className="table-responsive mb-8">
          <table className="table align-middle gs-0 gy-4 my-0">
            <thead>
              <tr className='border-bottom-1 border-gray-300'>
                <th className="w-auto fs-6 fw-bold">Item</th>
                <th className="w-auto fs-6 fw-bold">Quantity</th>
                <th className="w-auto fs-6 fw-bold">Total</th>
              </tr>
            </thead>
            <tbody>
              {currentOrder.length > 0 ? currentOrder.map((item) => (
                <tr key={item.id} data-kt-pos-element="item" data-kt-pos-item-price={item.price}>
                  <td className="pe-0">
                    <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-7 me-1" title={item.title}>
                      {truncate(item.title, 16)}
                    </span>
                  </td>
                  <td className="pe-0">
                    <div className="position-relative d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-icon btn-sm btn-light btn-icon-gray-900 border border-gray-500"
                        onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                      >
                        <KTSVG path='/media/icons/duotune/arrows/arr090.svg' className='svg-icon-4' />
                      </button>
                      <input
                        type="text"
                        className="form-control border-1 text-center px-0 fs-7 fw-bold text-gray-800 w-40px mx-2 bg-light-warning"
                        value={item.quantity}
                        onChange={(e) => {
                          const value = parseInt(e.target.value) || 0;
                          handleQuantityChange(item.id, value);
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-icon btn-sm btn-light btn-icon-gray-900 border border-gray-500"
                        onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                      >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-4' />
                      </button>
                    </div>
                  </td>
                  <td className="text-end">
                    <span className="fw-bold text-primary fs-7">
                      {(item.quantity * item.price).toFixed(2)} {CURRENCY_APP}
                    </span>
                  </td>
                </tr>
              )) :
                <tr>
                  <td colSpan={3} className='text-center py-5'>
                    No order items
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack bg-success rounded-3 p-6 mb-11">
          <div className="fs-6 fw-bold text-white">
            <span className="d-block lh-1 mb-2">Subtotal</span>
            <span className="d-block mb-2">Discount</span>
            <span className="d-block mb-9">Tax(16%)</span>
            <span className="d-block fs-2qx lh-1">Total</span>
          </div>
          <div className="fs-6 fw-bold text-white text-end">
            <span className="d-block lh-1 mb-2">{calculateTotal.subtotal.toFixed(2)} {CURRENCY_APP}</span>
            <span className="d-block mb-2">-{calculateTotal.discount.toFixed(2)} {CURRENCY_APP}</span>
            <span className="d-block mb-9">{calculateTotal.tax.toFixed(2)} {CURRENCY_APP}</span>
            <span className="d-block fs-2qx lh-1">{calculateTotal.total.toFixed(2)} {CURRENCY_APP}</span>
          </div>
        </div>
        <div className="m-0">
          <h1 className="fw-bold text-gray-800 mb-5">Payment Method</h1>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 mb-6">
            {paymentMethods.map((method) => (
              <div className="col" key={method.value}>
                <label className={`btn bg-light btn-color-gray-600 btn-active-text-gray-800 border border-3 ${selectedMethod === method.value ? 'border-primary' : 'border-gray-100'} btn-active-light-primary w-100 px-4 ${selectedMethod === method.value ? 'active' : ''}`}>
                  <input
                    className="btn-check"
                    type="radio"
                    name="method"
                    value={method.value}
                    checked={selectedMethod === method.value}
                    onChange={() => handleMethodChange(method.value)}
                  />
                  <div className="d-flex flex-column align-items-center" style={{ height: '80px', justifyContent: 'center' }}>
                    {method.icon.startsWith('/media/svg') ? (
                      <img
                        src={method.icon}
                        alt={method.label}
                        style={{ width: '60px', height: '35px', objectFit: 'contain' }}
                      />
                    ) : (
                      <KTSVG
                        path={method.icon}
                        className={method.size}
                      />
                    )}
                    <span className="fs-7 fw-bold d-block mt-2">{method.label}</span>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <button className="btn btn-primary fs-1 w-100 py-4 mb-5">Print Bills</button>
        </div>
      </div>
    </div>
  );
};

export default CurrentOrderPanel;
