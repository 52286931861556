import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {KTCard} from '../../../../_metronic/helpers'
import {KTCardBody} from '../../../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Maintable} from '../../../tables/Maintable'
import {useMemo} from 'react'
import LoadingBar from 'react-top-loading-bar'
import {useWindowWidth} from '@react-hook/window-size/throttled'

import CountUp from 'react-countup'

import {createColumnHelper} from '@tanstack/react-table'
import {useAuth} from '../../../../app/modules/auth'
import {ItemsSummary} from '../../../topdashboard/ItemsSummary'
import io from 'socket.io-client'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { queryClient } from '../../../..'
import { Maintabledescription } from '../../../tables/Maintabledescription'

import { DeleteInventoryBrands } from './modals/DeleteInventoryBrands'
import { AddInventoryBrands } from './modals/AddInventoryBrands'
import { UpdateInventoryBrands } from './modals/UpdateInventoryBrands'
import { useBrands } from '../../../contextProviders/inventory_management/Inventorybrandsprovider'
import { getBrands } from '../../../apis/inventory_management/Loadallbrands'
import { Maintableclass } from '../../../tables/inventory_management/Maintableclass'


const CURRENCY_APP = process.env.REACT_APP_CURRENCY


// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const socket = io('quecomsteelsocket.smatonsolutions.com')

const InventoryBrands = () => {
  const refloadingbar: any = useRef(null)
  // const queryClient: any = useQueryClient()
  const myRefsearch: any = useRef()
  const myRef: any = useRef()
  const myRef2: any = useRef()
  const {currentUser, logout} = useAuth()
  const truncate = require('truncate')

  const [pagination_size, setPagination_size] = useState<number>(10)

  const [start_limit, setStart_limit] = useState<number>(0)
  const [statusnow, setStatusnow] = useState<boolean>(false)
  const [statusnow2, setStatusnow2] = useState<boolean>(false)
  const [itemto_order, setItemto_order] = useState<string>('brand_id')
  const [desorasc, setDesorasc] = useState<string>('DESC')
  const [serversort, setServersort] = useState<any>([])
  const [inventorybrandsitemtodelete, setInventorybrandsitemtodelete] = useState<any>({})
  const [inventorybrandsitemtoupdate, setInventorybrandsitemtoupdate] = useState<any>({})
  const [search_term, setSearch_term] = useState<string>('%')
  const [pageCount, setPageCount] = useState<number>(0)
  const [balancesnew, setBalancesnew] = useState<any>([])
  const [creditdatanew, setCreditdatanew] = useState<any>([])
  const [debitdatanew, setDebitdatanew] = useState<any>([])
  const [modalshowdelete, setModalshowdelete] = useState(false)

  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)

  const [modalAddInventory, setModalAddInventory] = useState(false)
  const [modalUpdateInventory, setModalUpdateInventory] = useState(false)
  const onlyWidth = useWindowWidth()

  //inventorypackaging management

  const {
    isLoading: brandsisloading,
    isSuccess: brandsdsuccess,
    data: brandsdata,
    status: brandsstatus,
    refetch: brandsrefetch,
    isRefetching: brandsisRefetching,
  }: any = useBrands(
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  )
  //InventoryPackaging
  interface InventoryPackaging {
    brand_id: number;
    brand_name: string; 
    description?: string; 
    margin: number;
    active: string;
    country_of_origin: string;
    packaging_units?: number

   
  }

  useEffect(() => {
    const handleValueChange = () => {
      if (myRefsearch.current.value === '' && myRefsearch.current === document.activeElement) {
        setSearch_term('%')
        setPagination_size(10)
        setStart_limit(0)
        setItemto_order('brand_id')
        setDesorasc('DESC')
        setMovetofirstpage(true)
      }
    }

    const refSearch = myRefsearch.current
    if (refSearch) {
      refSearch.addEventListener('input', handleValueChange)
    }

    return () => {
      if (refSearch) {
        refSearch.removeEventListener('input', handleValueChange)
      }
    }
  }, [])

  //refetching when search tem, order by changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refloadingbar.current.continuousStart()
        await brandsrefetch()
      } catch (error) {
        console.log(error)
      } finally {
        refloadingbar.current.complete()
      }
    }
    fetchData()
  }, [itemto_order, desorasc, search_term])

  useEffect(() => {
    if (serversort.length > 0) {
      const {id, desc} = serversort[0]
      setItemto_order(id)
      setDesorasc(desc ? 'DESC' : 'ASC')
    } else {
      setItemto_order('brand_id')
      setDesorasc('DESC')
    }
  }, [serversort])

  const tabledata = useMemo(
    () => brandsdata?.data.brands,
    [brandsdsuccess, brandsdata?.data.brands]
  )

  const tabledatalength = useMemo(
    () => brandsdata?.data.total_count,
    [brandsdsuccess, brandsdata?.data.total_count]
  )

  const tablename = 'Pricing Information'

  useEffect(() => {
    const fetchData = async () => {
      const nextPage = Math.ceil(start_limit / pagination_size) + 1
      const queryKeyvalue = ['inventorybrands', nextPage * pagination_size, pagination_size]

      // Check if the next page data is already in the cache and is fresh
      const state = queryClient.getQueryState(queryKeyvalue)

      console.log(state)

      if (!state) {
        // If the data is not in the cache or it's stale, then prefetch the next page
        await queryClient.prefetchQuery({
          queryKey: queryKeyvalue,
          queryFn: () =>
            getBrands(
              nextPage * pagination_size,
              pagination_size,
              itemto_order,
              desorasc,
              search_term,
              Number(currentUser?.user_role_id),
              Number(currentUser?.user_designation_id)
            ),
        })
      }
    }

    fetchData()
  }, [
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    currentUser?.user_role_id,
    currentUser?.user_designation_id,
    queryClient,
  ])

  const columnHelper = createColumnHelper<InventoryPackaging>()

  const showModalAddInvenory = () => {
    setModalAddInventory(true)
    if (myRef && myRef.current) {
      myRef.current.Resetmodalvalues()
    }
  }

  const showModalUpdateInvenory = (x: any) => {
    setModalUpdateInventory(true)
    setInventorybrandsitemtoupdate(x)
    if (myRef2 && myRef2.current) {
      myRef2.current.Resetmodalvalues()
    }
  }

  const showModaldelete = (x: any) => {
    setModalshowdelete(true)

    setInventorybrandsitemtodelete(x)
  }

  const columndata1 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null, // No header
      meta: {
        width: 50, 
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
        <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3' >
          {row.getIsExpanded() ? (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          )}
        </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('brand_id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('brand_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()}</span>
            </span>
            
          </>
        )
      },
    }),
  
    columnHelper.accessor('country_of_origin', {
      header: 'origin',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('packaging_units', {
      header:()=> <div className='w-100 text-end'>units</div>,
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('margin', {
      header:()=>  <div className='w-100 text-end'>margin</div>,
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
          <span className='text-darker badge border border-primary fs-6 text-primary'> {`${(Number(info.getValue()))}%`} </span>
          </span>
        )
      },
    }),
    
    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              showModalUpdateInvenory(row.original)
            }}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              showModaldelete(row.original)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]

  const columndata3 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null, // No header
      meta: {
        width: 50, 
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
        <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3' >
          {row.getIsExpanded() ? (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          )}
        </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('brand_id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('brand_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()}</span>
            </span>
            
          </>
        )
      },
    }),
  
    columnHelper.accessor('country_of_origin', {
      header: 'origin',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('packaging_units', {
      header:()=> <div className='w-100 text-end'>units</div>,
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('margin', {
      header:()=>  <div className='w-100 text-end'>margin</div>,
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
          <span className='text-darker badge border border-primary fs-6 text-primary'> {`${(Number(info.getValue()))}%`} </span>
          </span>
        )
      },
    }),
    
    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              showModalUpdateInvenory(row.original)
            }}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              showModaldelete(row.original)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const columndata5 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null, // No header
      meta: {
        width: 50, 
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
        <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3' >
          {row.getIsExpanded() ? (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          )}
        </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('brand_id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('brand_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()}</span>
            </span>
            
          </>
        )
      },
    }),
  
    columnHelper.accessor('country_of_origin', {
      header: 'origin',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('packaging_units', {
      header:()=> <div className='w-100 text-end'>units</div>,
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('margin', {
      header:()=>  <div className='w-100 text-end'>margin</div>,
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
          <span className='text-darker badge border border-primary fs-6 text-primary'> {`${(Number(info.getValue()))}%`} </span>
          </span>
        )
      },
    }),
    
    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              showModalUpdateInvenory(row.original)
            }}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              showModaldelete(row.original)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const handlesearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const inputValue = event.target.value
      if (inputValue) {
        setSearch_term('%' + inputValue + '%')
      } else {
        setSearch_term('%')
      }
      setPagination_size(10)
      setStart_limit(0)
      setItemto_order('brand_id')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handleinputs = (event: any) => {
    if (myRefsearch.current.value === '' || event.target.value === '') {
      setSearch_term('%')
      setPagination_size(10)
      setStart_limit(0)
      setItemto_order('brand_id')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handlesearchKeyDown2 = (event: any) => {
    event.preventDefault()

    const inputValue = myRefsearch.current.value || event.target.value

    if (inputValue) {
      setSearch_term('%' + inputValue + '%')
    } else {
      setSearch_term('%')
    }

    setPagination_size(10)
    setStart_limit(0)
    setItemto_order('brand_id')
    setDesorasc('DESC')
    setMovetofirstpage(true)
  }

  useEffect(() => {
    // Define the function that will handle the 'update' event
    const handleSocketUpdate = (notification: any) => {
      ;(async () => {
        try {
          await refloadingbar.current.continuousStart()
          await queryClient.clear()
          await brandsrefetch()

          console.log(notification)
        } catch (error) {
          console.log(error)
        } finally {
          await refloadingbar.current.complete()
        }
      })()
    }

    socket.on('update', handleSocketUpdate)

    return () => {
      socket.off('update', handleSocketUpdate)
    }
  }, [])

  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Inventory Brands</PageTitle>
      {/* begin::Row */}
      
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='row mt-4'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className='mb-2'>
                    <h3 className='fw-bolder my-2'>Inventory Brands</h3>
                  </div>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                      <a href='#' onClick={handlesearchKeyDown2}>
                        <KTSVG
                          path='/media/icons/duotune/general/gen021.svg'
                          className='svg-icon-1 position-absolute me-4 top-0 end-0 mt-3 text-primary'
                        />
                      </a>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Inventory Brand'
                        ref={myRefsearch}
                        // getValue()={searchTitle}
                        onChange={handleinputs}
                        onKeyDown={handlesearchKeyDown}
                      />
                    </div>
                    {/* end::Search */}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    <a
                      className='btn btn-primary btn-sm mb-2 text-nowrap'
                      onClick={() => {
                        showModalAddInvenory()
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs029.svg'
                        className='svg-icon-1'
                      />
                      Add Inventory Brand
                    </a>
                  </div>
                </div>
              </div>

              <Maintableclass
                tabledata={brandsdsuccess ? tabledata : []}
                start_limit={start_limit}
                setStart_limit={setStart_limit}
                pagination_size={pagination_size}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPagination_size={setPagination_size}
                movetofirstpage={movetofirstpage}
                setMovetofirstpage={setMovetofirstpage}
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                
                table1loaded={brandsisloading}
                table_status={brandsstatus}
                table_success={brandsdsuccess}
          
                tablelength={tabledatalength}
                setServersort={setServersort}
              />
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <AddInventoryBrands
        show={modalAddInventory}
        handleClose={function (): void {
          setModalAddInventory(false)
        }}
        ref={myRef}
      />
      <DeleteInventoryBrands
        show={modalshowdelete}
        inventorybrandsitemtodelete={inventorybrandsitemtodelete}
        handleClose={function (): void {
          setModalshowdelete(false)
        }}
      />

      <UpdateInventoryBrands
        show={modalUpdateInventory}
        inventorybrandsitemtoupdate={inventorybrandsitemtoupdate}
        handleClose={function (): void {
          setModalUpdateInventory(false)
        }}
        ref={myRef2}
      />
    </>
  )
}

export default InventoryBrands
