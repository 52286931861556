import {KTSVG} from '../../../../../_metronic/helpers'
import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'

import {useAuth} from '../../../../../app/modules/auth'
import * as Yup from 'yup'
import clsx from 'clsx'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import { useAddClient } from '../../../../contextProviders/cs_management/Clientsprovider'



dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  ref: any
}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
export const AddClients: FC<Props> = forwardRef((props, ref) => {
 
  const [submittingReady, setSubmittingReady] = useState<boolean>(false)
  const [clientItemsAdded, setClientItemsAdded] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const initialValues = {
    address: '',
    email: '',
    phone: '',
    vat_no: '',
    business_name: '',
    registration_date: '',
    contact_person: '',
    contact_phone: '',
    contact_email: '',
    details: 'To be updated',
    location: ''
}


const eventSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  vat_no: Yup.string().required('VAT number is required'),
  business_name: Yup.string().required('Business name is required'),
  registration_date: Yup.date().required('Registration date is required'),
  contact_person: Yup.string().required('Contact person is required'),
  contact_phone: Yup.string().required('Contact phone number is required'),
  contact_email: Yup.string().email('Invalid email').required('Contact email is required'),
  details: Yup.string(),
  location: Yup.string().required('Location is required')
})


 

  const {mutateAsync: addClientmutateAsync} = useAddClient(clientItemsAdded)

  const formik = useFormik({
    initialValues: initialValues,
  
    validationSchema: eventSchema,
  
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
    
      setClientItemsAdded({
   
        address: values.address,
        email: values.email,
        phone: values.phone,
        vat_no: values.vat_no,
        business_name: values.business_name.toUpperCase(), // Assuming you only want the business name in uppercase
        registration_date: values.registration_date,
        contact_person: values.contact_person,
        contact_phone: values.contact_phone,
        contact_email: values.contact_email,
        details: values.details,
        location: values.location
      })
      setSubmittingReady(true); // Assuming this is meant to be 'setSubmittingReady'
      setLoading(true); // Assuming this is a function to indicate that an operation is in progress
      resetForm(); // Reset form after submission
    },
  })
  
  useImperativeHandle(ref, () => ({
    Resetmodalvalues() {
      formik.resetForm({})
  
      setSubmittingReady(false)
      setClientItemsAdded({})
      setLoading(false)
    },
  }))

  useEffect(() => {
    if (submittingReady && clientItemsAdded) {
      addClientmutateAsync(clientItemsAdded, {
        onSuccess: () => {
          setLoading(false)
          formik.resetForm({})
          formik.setSubmitting(false)
      
          setSubmittingReady(false)
          props.handleClose()
          setClientItemsAdded({})
        },
        onError(error: any, variables: any, context: any) {
          // Log the error for debugging
          console.error('API Error: ', error)
          setSubmittingReady(false)
          formik.setStatus('There is an error adding the Client Items.')
          setLoading(false)
          formik.setSubmitting(false)
        },
      })
    }
  }, [submittingReady])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_client_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Add Client</h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          {formik.status && (
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-center'>
                <span className='text-danger'>{formik.status}</span>
              </div>
            </div>
          )}
          <form
            id='kt_modal_add_client_form'
            noValidate
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={formik.handleSubmit}
          >
            {/* Unit Retail Price Input Group */}
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Business Name</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='business_name' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid text-uppercase'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('business_name')} // For touch feedback
                  value={formik.values.business_name} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.business_name && formik.errors.business_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.business_name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>VAT No.</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='vat_no' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid text-uppercase'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('vat_no')} // For touch feedback
                  value={formik.values.vat_no} // Control input with Formik's state
                   placeholder='P051208644E'
                />

                {/* Error Message for Item Name */}
                {formik.touched.vat_no && formik.errors.vat_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.vat_no}</span>
                    </div>
                  </div>
                )}
              </div>
           
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Business Address</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='address' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('address')} // For touch feedback
                  value={formik.values.address} // Control input with Formik's state
                  placeholder='P.O BOX 224586-00100, Nairobi, Kenya'
                />

                {/* Error Message for Item Name */}
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.address}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Location</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='location' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('location')} // For touch feedback
                  value={formik.values.location} // Control input with Formik's state
                  placeholder='12th Flr Hazina Towers, Monrovia Street, CBD'
                />

                {/* Error Message for Item Name */}
                {formik.touched.location && formik.errors.location && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.location}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

          
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Business Email</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='email' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('email')} // For touch feedback
                  value={formik.values.email} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Business Phone</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='phone' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('phone')} // For touch feedback
                  value={formik.values.phone} // Control input with Formik's state
              
                />

                {/* Error Message for Item Name */}
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone}</span>
                    </div>
                  </div>
                )}
              </div>
           
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Business Registration Date</label>
                {/* end::Label */}

                <input
                        type='date'
                        id='datetimePicker'
                        className='form-control form-control-lg form-control-solid datetime-picker'
                        // Removed defaultValue to rely on value for controlled component behavior
                        value={formik.values.registration_date}
                        onChange={(e) => formik.setFieldValue('registration_date', e.target.value)} // Added onChange handler
                        onBlur={formik.handleBlur('registration_date')} // Ensure onBlur is correctly targeting 'validity'
                      />

                {/* Error Message for Item Name */}
                {formik.touched.registration_date && formik.errors.registration_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.registration_date}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Contact Person</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='contact_person' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('contact_person')} // For touch feedback
                  value={formik.values.contact_person} // Control input with Formik's state
              
                />

                {/* Error Message for Item Name */}
                {formik.touched.contact_person && formik.errors.contact_person && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.contact_person}</span>
                    </div>
                  </div>
                )}
              </div>
           
            </div>

            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Contact Email</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='contact_email' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('contact_email')} // For touch feedback
                  value={formik.values.contact_email} // Control input with Formik's state
                  defaultValue={formik.values.contact_email}
                />

                {/* Error Message for Item Name */}
                {formik.touched.contact_email && formik.errors.contact_email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.contact_email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Contact Phone</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='contact_phone' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('contact_phone')} // For touch feedback
                  value={formik.values.contact_phone} // Control input with Formik's state
                  defaultValue={formik.values.phone}
                />

                {/* Error Message for Item Name */}
                {formik.touched.contact_phone && formik.errors.contact_phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.contact_phone}</span>
                    </div>
                  </div>
                )}
              </div>
           
            </div>




            <div className='row'>
              <div className='col-lg-12 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Business Details</label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange}
                  value={formik.values.details}
                  onBlur={formik.handleBlur} // Corrected onBlur handler
                  name='description'
                  rows={2}
                ></textarea>
                {formik.touched.details && formik.errors.details && (  



                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.details}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            form='kt_modal_add_client_form'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            {!loading && <span className='indicator-label'>Add Client</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
