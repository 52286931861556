import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {KTCard} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Maintable} from '../tables/Maintable'
import {useMemo} from 'react'
import LoadingBar from 'react-top-loading-bar'
import {useWindowWidth} from '@react-hook/window-size/throttled'

import CountUp from 'react-countup'

import {createColumnHelper} from '@tanstack/react-table'
import {useAuth} from '../../app/modules/auth'
import {ItemsSummary} from '../topdashboard/ItemsSummary'
import io from 'socket.io-client'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import {queryClient} from '../..'
import {useProject} from '../contextProviders/project_management/Projectmanagementprovider'
import {getProject} from '../apis/project_management/Loadallprojectmanagement'
import {AddProject} from './modals/AddProject'
import {DeleteProject} from './modals/DeleteProject'
import {UpdateProject} from './modals/UpdateProject'
import {ProgressBar} from 'react-bootstrap'
import {Maintableprojects} from '../tables/projects_management/Maintableprojects'

const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const socket = io('quecomsteelsocket.smatonsolutions.com')

const ProjectDashboard = () => {
  const refloadingbar: any = useRef(null)
  // const queryClient: any = useQueryClient()
  const myRefsearch: any = useRef()
  const myRef: any = useRef()
  const myRef2: any = useRef()
  const {currentUser, logout} = useAuth()
  const truncate = require('truncate')

  const [pagination_size, setPagination_size] = useState<number>(10)

  const [start_limit, setStart_limit] = useState<number>(0)
  const [statusnow, setStatusnow] = useState<boolean>(false)
  const [statusnow2, setStatusnow2] = useState<boolean>(false)
  const [itemto_order, setItemto_order] = useState<string>('id')
  const [desorasc, setDesorasc] = useState<string>('DESC')
  const [serversort, setServersort] = useState<any>([])
  const [projectitemtodelete, setProjectitemtodelete] = useState<any>({})
  const [projectitemtoupdate, setProjectitemtoupdate] = useState<any>({})
  const [search_term, setSearch_term] = useState<string>('%')
  const [pageCount, setPageCount] = useState<number>(0)
  const [balancesnew, setBalancesnew] = useState<any>([])
  const [creditdatanew, setCreditdatanew] = useState<any>([])
  const [debitdatanew, setDebitdatanew] = useState<any>([])
  const [modalshowdelete, setModalshowdelete] = useState(false)

  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)

  const [modalAddProject, setModalAddProject] = useState(false)
  const [modalUpdateProject, setModalUpdateProject] = useState(false)
  const onlyWidth = useWindowWidth()

  //project management

  const {
    isLoading: projectisloading,
    isSuccess: projectdsuccess,
    data: projectdata,
    status: projectstatus,
    refetch: projectrefetch,
    isRefetching: projectisRefetching,
  }: any = useProject(
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  )
  //Project

  type Project = {
    id: number
    client_id: number
    project_name: string
    start_date: string
    end_date: string
    status: string
    details: string
    user_id: number
    project_value: string
    progress: string
    created_at: string
    updated_at: string
    category_id: number
    category_name: string
    user_name: string
    quotation_id: number
  }

  useEffect(() => {
    const handleValueChange = () => {
      if (myRefsearch.current.value === '' && myRefsearch.current === document.activeElement) {
        setSearch_term('%')
        setPagination_size(10)
        setStart_limit(0)
        setItemto_order('id')
        setDesorasc('DESC')
        setMovetofirstpage(true)
      }
    }

    const refSearch = myRefsearch.current
    if (refSearch) {
      refSearch.addEventListener('input', handleValueChange)
    }

    return () => {
      if (refSearch) {
        refSearch.removeEventListener('input', handleValueChange)
      }
    }
  }, [])

  //refetching when search tem, order by changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refloadingbar.current.continuousStart()
        await projectrefetch()
      } catch (error) {
        console.log(error)
      } finally {
        refloadingbar.current.complete()
      }
    }
    fetchData()
  }, [itemto_order, desorasc, search_term])

  useEffect(() => {
    if (serversort.length > 0) {
      const {id, desc} = serversort[0]
      setItemto_order(id)
      setDesorasc(desc ? 'DESC' : 'ASC')
    } else {
      setItemto_order('id')
      setDesorasc('DESC')
    }
  }, [serversort])

  const tabledata = useMemo(
    () => projectdata?.data.projects,
    [projectdsuccess, projectdata?.data.projects]
  )

  const tabledatalength = useMemo(
    () => projectdata?.data.total_count,
    [projectdsuccess, projectdata?.data.total_count]
  )

  const tablename = 'Project Information'

  useEffect(() => {
    const fetchData = async () => {
      const nextPage = Math.ceil(start_limit / pagination_size) + 1
      const queryKeyvalue = ['project', nextPage * pagination_size, pagination_size]

      // Check if the next page data is already in the cache and is fresh
      const state = queryClient.getQueryState(queryKeyvalue)

      console.log(state)

      if (!state) {
        // If the data is not in the cache or it's stale, then prefetch the next page
        await queryClient.prefetchQuery({
          queryKey: queryKeyvalue,
          queryFn: () =>
            getProject(
              nextPage * pagination_size,
              pagination_size,
              itemto_order,
              desorasc,
              search_term,
              Number(currentUser?.user_role_id),
              Number(currentUser?.user_designation_id)
            ),
        })
      }
    }

    fetchData()
  }, [
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    currentUser?.user_role_id,
    currentUser?.user_designation_id,
    queryClient,
  ])

  const columnHelper = createColumnHelper<Project>()

  const showModalAddInvenory = () => {
    setModalAddProject(true)
    if (myRef && myRef.current) {
      myRef.current.Resetmodalvalues()
    }
  }

  const showModalUpdateInvenory = (x: any) => {
    setModalUpdateProject(true)
    setProjectitemtoupdate(x)
    if (myRef2 && myRef2.current) {
      myRef2.current.Resetmodalvalues()
    }
  }

  const showModaldelete = (x: any) => {
    setModalshowdelete(true)

    setProjectitemtodelete(x)
  }

  const columndata1 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null,
      meta: {
        width: 50,
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
        <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3' >
          {row.getIsExpanded() ? (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          )}
        </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('project_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.project_name.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {addCommas(info.row.original.project_value)} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('start_date', {
      header: () => 'Duration',
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              <span className='badge badge-light-info fs-6'>
                {dayjs(info.getValue()).format('ll')}
              </span>
            </span>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              <span className='badge badge-light-success fs-6'>
                {dayjs(info.row.original.end_date).format('ll')}
              </span>
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('category_name', {
      header: 'Category',
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              {info.getValue().toUpperCase()}
            </span>
            <span className='text-nowrap fw-bold d-block mb-1 fs-6'>
              <span
                className={`fs-6 badge border border-${
                  info.row.original.status === 'Pending'
                    ? 'warning'
                    : info.row.original.status === 'Completed'
                    ? 'success'
                    : 'primary'
                } text-${
                  info.row.original.status === 'Pending'
                    ? 'warning'
                    : info.row.original.status === 'Completed'
                    ? 'success'
                    : 'primary'
                }`}
              >
                {info.row.original.status.toUpperCase()}
              </span>
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('progress', {
      header: 'Progress',
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              <div className='w-100 text-end'>
                <span className='badge badge-light fs-6'>{`${
                  Number(info.row.original.progress ?? 0) * 100
                }%`}</span>
              </div>
              <div className='progress h-8px w-100 mt-3'>
                <div
                  className='progress-bar progress-bar-striped progress-bar-animated bg-info'
                  role='progressbar'
                  style={{width: `${Number(info.row.original.progress ?? 0) * 100}%`}}
                ></div>
              </div>
            </span>
          </>
        )
      },
    }),

    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </a>

          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              showModalUpdateInvenory(row.original)
            }}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              showModaldelete(row.original)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]

  
  const columndata3 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null,
      meta: {
        width: 50,
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
        <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3' >
          {row.getIsExpanded() ? (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          )}
        </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('project_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {truncate(info.row.original.project_name, 20).toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {addCommas(info.row.original.project_value)} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
  
    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </a>

          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              showModalUpdateInvenory(row.original)
            }}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              showModaldelete(row.original)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]


  const columndata5 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null,
      meta: {
        width: 50,
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
        <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3' >
          {row.getIsExpanded() ? (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            <>
              <span className='svg-icon svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <g fill='none' fillRule='evenodd'>
                    <path d='M0 0L24 0 24 24 0 24z'></path>
                    <path
                      fill='#009ef7'
                      fillRule='nonzero'
                      d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                    ></path>
                  </g>
                </svg>
              </span>
            </>
          )}
        </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('project_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.project_name.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {addCommas(info.row.original.project_value)} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('start_date', {
      header: () => 'Duration',
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              <span className='badge badge-light-info fs-6'>
                {dayjs(info.getValue()).format('ll')}
              </span>
            </span>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              <span className='badge badge-light-success fs-6'>
                {dayjs(info.row.original.end_date).format('ll')}
              </span>
            </span>
          </>
        )
      },
    }),
    

    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </a>

          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              showModalUpdateInvenory(row.original)
            }}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              showModaldelete(row.original)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const handlesearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const inputValue = event.target.value
      if (inputValue) {
        setSearch_term('%' + inputValue + '%')
      } else {
        setSearch_term('%')
      }
      setPagination_size(10)
      setStart_limit(0)
      setItemto_order('id')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handleinputs = (event: any) => {
    if (myRefsearch.current.value === '' || event.target.value === '') {
      setSearch_term('%')
      setPagination_size(10)
      setStart_limit(0)
      setItemto_order('id')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handlesearchKeyDown2 = (event: any) => {
    event.preventDefault()

    const inputValue = myRefsearch.current.value || event.target.value

    if (inputValue) {
      setSearch_term('%' + inputValue + '%')
    } else {
      setSearch_term('%')
    }

    setPagination_size(10)
    setStart_limit(0)
    setItemto_order('id')
    setDesorasc('DESC')
    setMovetofirstpage(true)
  }

  useEffect(() => {
    // Define the function that will handle the 'update' event
    const handleSocketUpdate = (notification: any) => {
      ;(async () => {
        try {
          await refloadingbar.current.continuousStart()
          await queryClient.clear()
          await projectrefetch()

          console.log(notification)
        } catch (error) {
          console.log(error)
        } finally {
          await refloadingbar.current.complete()
        }
      })()
    }

    socket.on('update', handleSocketUpdate)

    return () => {
      socket.off('update', handleSocketUpdate)
    }
  }, [])

  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Project Management</PageTitle>
      {/* begin::Row */}
      {/* begin::Row */}

      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}

        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='120px'
            cardName='Balance'
            chartName='Balance'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...balancesnew?.map((x: any) => x.Closing_Balance)))}
            chartCategories={balancesnew
              ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
              .map((x: any) => dayjs(x.id).format('LL'))}
            chartData={balancesnew
              ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
              .map((x: any) => x.Closing_Balance)}
            values={
              <>
                <div className='fs-2 fw-bolder d-block '>
                  <CountUp
                    className='text-primary'
                    end={
                      balancesnew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.Closing_Balance)[0]
                    }
                    duration={2}
                    decimals={2}
                    separator=','
                  />{' '}
                  <sup className='fs-6 text-primary'>{CURRENCY_APP}</sup>
                </div>
              </>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {balancesnew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.id)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                balancesnew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.id)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                balancesnew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.Closing_Balance)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {balancesnew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.id)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                balancesnew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.id)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                balancesnew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.Closing_Balance)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='120px'
            cardName='Credit'
            chartName='Credit'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...creditdatanew?.map((x: any) => x.TotalCredit)))}
            chartCategories={creditdatanew
              ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
              .map((x: any) => dayjs(x.id).format('LL'))}
            chartData={creditdatanew
              ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
              .map((x: any) => x.TotalCredit)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-danger'
                  end={
                    creditdatanew
                      ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                      .map((x: any) => x.TotalCredit)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-danger fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {creditdatanew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.id)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-danger'>
                              {dayjs(
                                creditdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.id)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                creditdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.TotalCredit)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {creditdatanew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.id)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-danger'>
                              {dayjs(
                                creditdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.id)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                creditdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.TotalCredit)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='success'
            chartHeight='120px'
            cardName='Debit'
            chartName='Debit'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...debitdatanew?.map((x: any) => x.TotalDebit)))}
            chartCategories={debitdatanew
              ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
              .map((x: any) => dayjs(x.id).format('LL'))}
            chartData={debitdatanew
              ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
              .map((x: any) => x.TotalDebit)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-success'
                  end={
                    debitdatanew
                      ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                      .map((x: any) => x.TotalDebit)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-success fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {debitdatanew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.id)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                                debitdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.id)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                debitdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.TotalDebit)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {debitdatanew
                        ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                        .map((x: any) => x.id)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                                debitdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.id)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                debitdatanew
                                  ?.sort((a: any, b: any) => (a.id > b.id ? -1 : 1))
                                  .map((x: any) => x.TotalDebit)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='row mt-4'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className='mb-2'>
                    <h3 className='fw-bolder my-2'>Project Management</h3>
                  </div>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                      <a href='#' onClick={handlesearchKeyDown2}>
                        <KTSVG
                          path='/media/icons/duotune/general/gen021.svg'
                          className='svg-icon-1 position-absolute me-4 top-0 end-0 mt-3 text-primary'
                        />
                      </a>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Project'
                        ref={myRefsearch}
                        // getValue()={searchTitle}
                        onChange={handleinputs}
                        onKeyDown={handlesearchKeyDown}
                      />
                    </div>
                    {/* end::Search */}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    <a
                      className='btn btn-info btn-sm mb-2 text-nowrap'
                      onClick={() => {
                        showModalAddInvenory()
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen006.svg'
                        className='svg-icon-1'
                      />
                      Add Project
                    </a>
                  </div>
                </div>
              </div>

              <Maintableprojects
                tabledata={projectdsuccess ? tabledata : []}
                start_limit={start_limit}
                setStart_limit={setStart_limit}
                pagination_size={pagination_size}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPagination_size={setPagination_size}
                movetofirstpage={movetofirstpage}
                setMovetofirstpage={setMovetofirstpage}
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                table1loaded={projectisloading}
                table_status={projectstatus}
                table_success={projectdsuccess}
                tablelength={tabledatalength}
                setServersort={setServersort}
              />
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <AddProject
        show={modalAddProject}
        handleClose={function (): void {
          setModalAddProject(false)
        }}
        ref={myRef}
      />
      <DeleteProject
        show={modalshowdelete}
        projectitemtodelete={projectitemtodelete}
        handleClose={function (): void {
          setModalshowdelete(false)
        }}
      />

      <UpdateProject
        show={modalUpdateProject}
        projectitemtoupdate={projectitemtoupdate}
        handleClose={function (): void {
          setModalUpdateProject(false)
        }}
        ref={myRef2}
      />
    </>
  )
}

export default ProjectDashboard
