import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {debounce} from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {KTCard} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Maintable} from '../tables/Maintable'
import {useMemo} from 'react'
import {createColumnHelper} from '@tanstack/react-table'
import LoadingBar from 'react-top-loading-bar'

import {useWindowWidth} from '@react-hook/window-size/throttled'
import { useInventory } from '../contextProviders/inventory_management/Inventoryprovider'
import CountUp from 'react-countup'

import {Column} from 'react-table'
import {useAuth} from '../../app/modules/auth'
import {ItemsSummary} from '../topdashboard/ItemsSummary'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import io from 'socket.io-client'



import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Maintableinventory } from '../tables/inventory_management/Maintableinventory'
import { Inventory_management } from '../inventory_management/InventoryDashboard'
import { useNavigate } from 'react-router-dom'
import { Subtableinventory } from '../tables/inventory_management/Subtableinventory'
import InventoryClassification from './InventoryClassification'
import CurrentOrderPanel from './CurrentOrderPanel'





const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
// const socket = io('machakosgolflocalsocket.smatonsolutions.com')



const Home = () => {

  const refloadingbar: any = useRef(null)
  const queryClient = useQueryClient()
  const myRefsearch: any = useRef()

  const myRefupdate: any = useRef()
  const {currentUser, logout} = useAuth()
  const truncate = require('truncate')

  const navigate = useNavigate(); 
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [pagination_size, setPagination_size] = useState<number>(10)

  const [start_limit, setStart_limit] = useState<number>(0)
  const [itemto_order, setItemto_order] = useState<string>('id')

  const [startlimitsumdash, setStartlimitsumdash] = useState<number>(0)
  const [database_limitsumdash, setDatabase_limitsumdash] = useState<number>(7)
  const [statusssumdash, setStatussumdash] = useState<string>('GroupedDate')
  const [statusnow, setStatusnow] = useState<boolean>(false)
  const [statusnow2, setStatusnow2] = useState<boolean>(false)

  const [desorasc, setDesorasc] = useState<string>('DESC')
  const [serversort, setServersort] = useState<any>([])
  const [search_term, setSearch_term] = useState<string>('%')
  
  const [pageCount, setPageCount] = useState<number>(0)
  const [balancesnew, setBalancesnew] = useState<any>([])
  const [creditdatanew, setCreditdatanew] = useState<any>([])
  const [debitdatanew, setDebitdatanew] = useState<any>([])
 
  const [hiddencolumnsmade, setHiddencolumnsmade] = useState<any>(['json_result'])
  const [hiddencolumnsmade2, setHiddencolumnsmade2] = useState<any>(['Project'])
  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)
  const onlyWidth = useWindowWidth()
  const columnHelper = createColumnHelper<Inventory_management>()
  const convertStringToJsonArray = (inputString: string) => {
    try {
      let jsonArray = JSON.parse(inputString);

      // Convert 'Date' fields from string to Date objects
      jsonArray = jsonArray.map((item: any) => {
        if (item.Date && item.Date.includes('function Date()')) {
          item.Date = new Date();
        }
        return item;
      });

      return jsonArray;
    } catch (error) {
      console.error('Error converting string to JSON:', error);
      return [];
    }
  };
//inventory management

const {
  isLoading: inventory_managementisloading,
  isSuccess: inventory_managementdsuccess,
  data: inventory_managementdata,
  status: inventory_managementstatus,
  refetch: inventory_managementrefetch,
  isRefetching: inventory_managementisRefetching,
}: any = useInventory(
  start_limit,
  pagination_size,
  itemto_order,
  desorasc,
  search_term,
  Number(currentUser?.user_role_id),
  Number(currentUser?.user_designation_id)
)
//inventory management

const tabledata = useMemo(
  () => inventory_managementdata?.data.inventory_management,
  [inventory_managementdsuccess, inventory_managementdata?.data.inventory_management]
)

const tabledatalength = useMemo(
  () => inventory_managementdata?.data.total_count,
  [inventory_managementdsuccess, inventory_managementdata?.data.total_count]
)

  

  useEffect(() => {
    if (serversort.length > 0) {
      setItemto_order(serversort.map((x: any) => x.id)[0])
      setDesorasc(serversort.map((x: any) => x.desc)[0] ? 'DESC' : 'ASC')
    } else {
      setItemto_order('GroupedDate')
      setDesorasc('DESC')
    }
  }, [serversort])

 
 



  // useEffect(() => {
  //   // Define the function that will handle the 'update' event
  //   const handleSocketUpdate = (notification: any) => {
  //     (async () => {
  //       try {
  //         await refloadingbar.current.continuousStart();
       
         
         
  //         console.log(notification);
        
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         await refloadingbar.current.complete();
  //       }
  //     })();
  //   };

    
  //   socket.on('update', handleSocketUpdate);

  //   return () => {
  //     socket.off('update', handleSocketUpdate);
  //   };
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      await inventory_managementrefetch();
    };
  
    fetchData();
  }, []);
  const columndata1 = [
   
    columnHelper.accessor('id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('item_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.item_name.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.inventory_code.toUpperCase()}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('quantity', {
      header: () => 'No.',
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              {info.getValue()}{' '}
              <span className='badge badge-light-info fs-6'>
                {info.row.original.packaging_type.toUpperCase()}
              </span>{' '}
            </span>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.row.original.part_number.toUpperCase()}
          </span>
          </>
        )
      },
    }),
   
  
  ]
 

  const columndata3 = [
   
    columnHelper.accessor('id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('item_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {truncate(info.row.original.item_name,20).toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.inventory_code.toUpperCase()}
            </span>
          </>
        )
      },
    }),
    

    columnHelper.accessor('unit_landing_cost', {
      header:()=> <div className='w-100 text-end'>Pricing</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap d-block mb-1 text-end '>
            <span className='fw-bolder fs-6'>{addCommas(info.row.original.unit_landing_cost)} {CURRENCY_APP}</span>
          </span>
          <span className='text-darker text-nowrap d-block mb-1 text-end fs-6'>
            <span className='fw-bolder fs-6'>{addCommas(info.row.original.unit_selling_price)} {CURRENCY_APP}</span>
          </span>
          </>
        )
      },
    })
  ]
  const columndata5 = [
   
    columnHelper.accessor('id', {
      header: 'id',
      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            {info.getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor('item_name', {
      header: 'Name',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.item_name.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.inventory_code.toUpperCase()}
            </span>
          </>
        )
      },
    }),
    
    columnHelper.accessor('unit_landing_cost', {
      header:()=> <div className='w-100 text-end'>Pricing</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className=''>{addCommas(info.row.original.unit_landing_cost)} {CURRENCY_APP}</span>
          </span>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className=''>{addCommas(info.row.original.unit_selling_price)} {CURRENCY_APP}</span>
          </span>
          </>
        )
      },
    }),
    
  
  ]
 


 
  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Home</PageTitle>
      {/* begin::Row */}
      {/* begin::Row */}

      <div className='row g-5 g-xl-8'>
      
      <InventoryClassification />
      
      </div>
      {/* end::Row */}
   
     
      

    </>
  )
}

export default Home