import React, { useState, useRef, useEffect } from 'react';
import { SearchItem } from './SearchService';
import { KTSVG } from '../../../_metronic/helpers';


interface SearchInputWithSuggestionsProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  suggestions: SearchItem[];
  isLoading: boolean;
  onSelectSuggestion: (item: SearchItem) => void;
}

const SearchInputWithSuggestions: React.FC<SearchInputWithSuggestionsProps> = ({
  searchQuery,
  setSearchQuery,
  suggestions,
  isLoading,
  onSelectSuggestion,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        !inputRef.current?.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [suggestions]);

  const handleInputFocus = () => {
    setShowSuggestions(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (item: SearchItem) => {
    onSelectSuggestion(item);
    setSearchQuery(item.title);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedIndex >= 0 && selectedIndex < suggestions.length) {
        handleSuggestionClick(suggestions[selectedIndex]);
      }
    } else if (e.key === 'Escape') {
      setShowSuggestions(false);
    }
  };

  return (
    <div className="position-relative">
      <div className="d-flex align-items-center position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute me-4 top-0 end-0 mt-3 text-primary"
        />
        <input
          type="text"
          className="form-control form-control-solid w-md-250px w-lg-350px col-sm-250px col-xs-250px ps-5 bg-light-warning border-1 border-gray-300"
          placeholder="Search Items"
          value={searchQuery}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
      </div>
      {showSuggestions && (suggestions.length > 0 || isLoading) && (
        <div
          ref={suggestionsRef}
          className="position-absolute bg-white shadow-sm w-100 mt-1 rounded-bottom border border-gray-200 z-index-3"
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          {isLoading ? (
            <div className="p-3 text-center">Loading...</div>
          ) : (
            suggestions.map((item, index) => (
              <div
                key={item.id}
                className={`p-2 cursor-pointer ${
                  index === selectedIndex ? 'bg-light-primary' : 'hover-bg-light'
                }`}
                onClick={() => handleSuggestionClick(item)}
              >
                {item.title}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SearchInputWithSuggestions;