import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useAuth } from '../../app/modules/auth';
import CurrentOrderPanel from './CurrentOrderPanel';
import { useCategories } from '../contextProviders/inventory_management/Inventorycategoriesprovider';
import { KTSVG } from '../../_metronic/helpers';
import { SearchItem } from '../helpers/components/SearchService';
import { useSearch } from '../helpers/components/useSearch';
import SearchInputWithSuggestions from '../helpers/components/SearchInputWithSuggestions';


const CURRENCY_APP = process.env.REACT_APP_CURRENCY;
const truncate = require('truncate');

interface InventoryItem {
  id: string;
  icon: string;
  title: string;
  count: number;
  countLabel: string;
}

interface InventoryContent {
  id: string;
  items: SearchItem[];
}

interface OrderItem {
  id: string;
  title: string;
  quantity: number;
  price: number;
}

const InventoryClassification: React.FC = () => {
  const { currentUser } = useAuth();
  const [currentOrder, setCurrentOrder] = useState<OrderItem[]>([]);
  const [stock, setStock] = useState<Record<number, number>>({});
  

  const {
    isLoading: categoriesIsLoading,
    isSuccess: categoriesSuccess,
    data: categoriesData,
  } = useCategories(
    0,
    100000,
    'category_id',
    'DESC',
    '%',
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  );

  const inventoryItems = useMemo<InventoryItem[]>(
    () =>
      categoriesData?.data.categories.map((category: any) => ({
        id: `kt_pos_food_content_${category.category_id}`,
        icon: `/media/svg/${category.icon_dark}`,
        title: category.category_name,
        count: category.item_count || 0,
        countLabel: category.item_count === 1 ? 'Item' : 'Items',
      })) || [],
    [categoriesData]
  );

  const inventoryContents = useMemo<InventoryContent[]>(
    () =>
      categoriesData?.data.enrichedCategoriesResults.map((category: any) => ({
        id: `kt_pos_food_content_${category.category_id}`,
        items: category.items.map((item: any) => ({
          id: item.id,
          image: `/media/svg/${item.icon_dark}`,
          title: item.item_name,
          quantity: item.quantity,
          price: item.unit_selling_price,
          categoryId: `kt_pos_food_content_${category.category_id}`,
        })),
      })) || [],
    [categoriesData]
  );

  useEffect(() => {
    if (categoriesData) {
      const initialStock: Record<number, number> = {};
      categoriesData.data.enrichedCategoriesResults.forEach((category: any) => {
        category.items.forEach((item: any) => {
          initialStock[item.id] = item.quantity;
        });
      });
      setStock(initialStock);
    }
  }, [categoriesData]);

  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inventoryItems.length > 0) {
      setActiveTab(inventoryItems[0].id);
    }
  }, [inventoryItems]);

  

  const allItems = useMemo(() => 
    inventoryContents.flatMap(content => content.items),
    [inventoryContents]
  );

  const { searchQuery, setSearchQuery, searchResults, isLoading: searchIsLoading } = useSearch(allItems);

  const handleTabClick = useCallback((id: string) => {
    setActiveTab(id);
    setSearchQuery(''); // Clear search input when changing tabs
    setHighlightedCategory(null); // Reset highlighted category
  }, [setSearchQuery]);

  const [highlightedCategory, setHighlightedCategory] = useState<string | null>(null);

  useEffect(() => {
    if (searchResults.length > 0) {
      setHighlightedCategory(searchResults[0].categoryId);
      setActiveTab(searchResults[0].categoryId);
    } else {
      setHighlightedCategory(null);
    }
  }, [searchResults]);

  const filteredContents = useMemo(() => {
    if (!searchQuery) return inventoryContents;
    
    return inventoryContents.map(content => ({
      ...content,
      items: content.items.filter(item => 
        searchResults.some(result => result.categoryId === content.id && result.items.some(resultItem => resultItem.id === item.id))
      ),
    }));
  }, [inventoryContents, searchResults, searchQuery]);

  const addToOrder = useCallback((item: SearchItem) => {
    if (stock[item.id] > 0) {
      setStock((prevStock) => ({
        ...prevStock,
        [item.id]: prevStock[item.id] - 1,
      }));

      setCurrentOrder((prevOrder) => {
        const existingItem = prevOrder.find((orderItem) => orderItem.id === item.id.toString());
        if (existingItem) {
          return prevOrder.map((orderItem) =>
            orderItem.id === item.id.toString()
              ? { ...orderItem, quantity: orderItem.quantity + 1 }
              : orderItem
          );
        } else {
          return [...prevOrder, { id: item.id.toString(), title: item.title, quantity: 1, price: item.price }];
        }
      });
    }
  }, [stock]);

  const updateOrderQuantity = useCallback((itemId: string, newQuantity: number) => {
    setCurrentOrder((prevOrder) =>
      prevOrder
        .map((item) =>
          item.id === itemId ? { ...item, quantity: newQuantity } : item
        )
        .filter((item) => item.quantity > 0)
    );

    setStock((prevStock) => {
      const orderItem = currentOrder.find((item) => item.id === itemId);
      if (orderItem) {
        const quantityDifference = orderItem.quantity - newQuantity;
        return {
          ...prevStock,
          [itemId]: prevStock[Number(itemId)] + quantityDifference,
        };
      }
      return prevStock;
    });
  }, [currentOrder]);

  const clearOrder = useCallback(() => {
    setCurrentOrder([]);
    setStock((prevStock) => {
      const resetStock = { ...prevStock };
      currentOrder.forEach((item) => {
        resetStock[Number(item.id)] += item.quantity;
      });
      return resetStock;
    });
  }, [currentOrder]);

  const calculateTotal = useMemo(() => {
    const subtotal = currentOrder.reduce((sum, item) => sum + item.quantity * item.price, 0);
    const discount = 0; // You can implement a discount logic if needed
    const tax = subtotal * 0.16; // 16% tax
    const total = subtotal + tax - discount;
    return { subtotal, discount, tax, total };
  }, [currentOrder]);

  const handleSelectSuggestion = useCallback((item: SearchItem) => {
    setHighlightedCategory(item.categoryId);
    setActiveTab(item.categoryId);
    addToOrder(item);
    setSearchQuery(''); // Clear search input after selecting a suggestion
  }, [setHighlightedCategory, setActiveTab, addToOrder, setSearchQuery]);

  if (categoriesIsLoading) {
    return <p>Loading...</p>;
  }

  if (!categoriesSuccess) {
    return <p>Error loading categories</p>;
  }

  if (inventoryItems.length === 0) {
    return <p>No categories available</p>;
  }

  return (
    <>
      <div className="col-8 col-lg-8 col-md-6 col-sm-5 col-xs-12">
        <div className='row mt-4'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
            <div className='mb-2'>
              <h3 className='fw-bolder my-2'>Add Sales</h3>
            </div>
          </div>
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <div className='mb-2'>
              <SearchInputWithSuggestions
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                suggestions={searchResults.flatMap(result => result.items)}
                isLoading={searchIsLoading}
                onSelectSuggestion={handleSelectSuggestion}
              />
            </div>
          </div>
        </div>
        <ul className="nav nav-pills gap-2 mb-6 d-flex flex-wrap justify-content-start">
          {inventoryItems.map((item) => (
            <li key={item.id} className="w-125px" role="presentation">
              <a
                className={`nav-link d-flex flex-column align-items-center p-4 border rounded-3 mb-2 ${
                  item.id === activeTab
                    ? 'border-primary border-3'
                    : item.id === highlightedCategory
                    ? 'border-warning border-3'
                    : 'border-secondary border-3'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(item.id);
                }}
                href="#"
                role="tab"
                aria-controls={item.id}
                aria-selected={item.id === activeTab}
              >
                <div className="mb-3">
                  <img src={item.icon} className="img-fluid w-40px" alt={item.title} />
                </div>
                <div className="text-center">
                  <span className="d-block fw-bold fs-7" title={item.title}>
                    {truncate(item.title, 10)}
                  </span>
                  <small className="text-muted">
                    {item.count} {item.countLabel}
                  </small>
                </div>
              </a>
            </li>
          ))}
        </ul>

        <div className="tab-content">
          {searchIsLoading ? (
            <div>Searching...</div>
          ) : (
            filteredContents.map((content) => (
              <div
                key={content.id}
                className={`tab-pane fade ${content.id === activeTab ? 'show active' : ''}`}
                id={content.id}
                role="tabpanel"
              >
                <div className="row g-3">
                  {content.items.map((item) => (
                    <div key={item.id} className="col-12 col-md-6 col-lg-4">
                      <div className="card h-100" onClick={() => addToOrder(item)}>
                        <div className="card-body text-center">
                          <img
                            src={item.image}
                            className="img-fluid rounded-3 mb-4"
                            style={{ width: '150px', height: '150px' }}
                            alt={item.title}
                          />
                          <div className="mb-2">
                            <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-6">
                              {item.title}
                            </span>
                            <span className="text-gray-500 fw-semibold d-block fs-7 mt-n1">
                              Quantity: {stock[item.id] || 0}
                            </span>
                          </div>
                          <span className="text-success text-end fw-bold fs-5">
                            {item.price.toFixed(2)} <sup>{CURRENCY_APP}</sup>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {content.items.length === 0 && (
                  <div className="text-center py-5">
                    <p>No items found in this category.</p>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
      <div className="col-4 col-lg-4 col-md-6 col-sm-7 col-xs-12">
        <CurrentOrderPanel
          currentOrder={currentOrder}
          clearOrder={clearOrder}
          updateOrderQuantity={updateOrderQuantity}
          calculateTotal={calculateTotal}
          stock={stock}
        />
      </div>
    </>
  );
};

export default InventoryClassification;