// src/services/searchService.ts
import Fuse from 'fuse.js';

export interface SearchItem {
  id: number;
  title: string;
  image: string;
  quantity: number;
  price: number;
  categoryId: string;
}

class SearchService {
  private fuse: Fuse<SearchItem>;

  constructor(items: SearchItem[]) {
    this.fuse = new Fuse(items, {
      keys: ['title'],
      threshold: 0.3,
      includeScore: true,
    });
  }

  async search(query: string): Promise<SearchItem[]> {
    const fuseResults = this.fuse.search(query);
    return fuseResults.map(result => result.item);
  }
}

export default SearchService;