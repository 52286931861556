import { useState, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import SearchService, { SearchItem } from './SearchService';

export interface CategorySearchResult {
  categoryId: string;
  items: SearchItem[];
}

interface UseSearchResult {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  searchResults: CategorySearchResult[];
  isLoading: boolean;
}

export const useSearch = (items: SearchItem[]): UseSearchResult => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<CategorySearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchService = useMemo(() => new SearchService(items), [items]);

  const debouncedSearch = useMemo(() => debounce(async (query: string) => {
    setIsLoading(true);
    
    if (!query) {
      setSearchResults([]);
    } else {
      const results = await searchService.search(query);
      const groupedResults = results.reduce((acc, item) => {
        if (!acc[item.categoryId]) {
          acc[item.categoryId] = [];
        }
        acc[item.categoryId].push(item);
        return acc;
      }, {} as Record<string, SearchItem[]>);

      const categoryResults = Object.entries(groupedResults).map(([categoryId, items]) => ({
        categoryId,
        items,
      }));

      setSearchResults(categoryResults);
    }
    
    setIsLoading(false);
  }, 100), [searchService]);

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
    debouncedSearch(query);
  }, [debouncedSearch]);

  return { searchQuery, setSearchQuery: handleSearch, searchResults, isLoading };
};